 b<template>
  <div>
    <a-modal v-model:visible="visible" title="处方详情" @ok="visible=false" okText="确认" cancelText="取消" :afterClose="closeModal" width=" 45vw" :cancelButtonProps="{ style: { display: 'none' }}">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
        <div class="style1">
          <div class="box">
            <label>处方名称：</label>
            <a-input v-model:value="info.name" style="width: 80%;margin-bottom: 20px;" />
          </div>
          <div class="box">
            <label>处方描述：</label>
            <a-textarea class="noResize" v-model:value="info.description" style="width: 80%;margin-bottom: 20px;" />
          </div>
          <div class="inputSave">
            <check-circle-filled style="color:#389e0d;font-size:25px" @click="update" />
          </div>
        </div>
        <!-- <hr style="margin-bottom: 15px;"> -->
        <div class="head">
          <span>药品：</span>
          <medicineSelector v-model="medicine" />
          <a-button @click="addToTable()">添加</a-button>
        </div>
        <a-table class="ant-table-striped" :columns="columns" :data-source="data" rowKey="medicineId" :pagination="false" :scroll="{  y: 400 }">
          <template v-for="col in ['itemName', 'description']" #[col]="{ text, record }" :key="col">
            <div>
              <!-- <div>{{record.id}}</div> -->
              <a-input v-if="editableData[record.id]" v-model:value="editableData[record.id][col]" style="margin: -5px 0" />
              <template v-else>{{ text }}</template>
            </div>
            <!-- <div>
              <a-input-number v-model:value="record[col]" style="margin: -5px 0" />
            </div> -->
          </template>
          <template #operation="{ record }">
            <div class="flex_sa">
              <div class="editable-row-operations flex_sa">
                <span v-if="editableData[record.id]">
                  <a @click="save(record.id)">保存</a>
                </span>
                <span v-if="editableData[record.id]">
                  <a @click="cancel(record.id)">取消</a>
                </span>
                <span v-else>
                  <a @click="edit(record.id)">编辑</a>
                </span>
              </div>
              <div class="editable-row-operations">
                <span>
                  <a-popconfirm title="是否确认删除" @confirm="onDelete(record.id)" okText="确认" cancelText="取消">
                    <a>删除</a>
                  </a-popconfirm>
                </span>
              </div>
            </div>
          </template>
        </a-table>
      </a-form>

    </a-modal>
  </div>
</template>

<script>
import {
  CheckCircleFilled
} from "@ant-design/icons-vue";
import { reactive } from "vue";
import { cloneDeep } from "lodash-es";
import { Detail, Update, UpdateMedicine, AddMedicine, DeleteMedicine } from "/src/api/prescription.js";
import medicineSelector from '/src/components/selector/medicineSelector.vue';
export default {
  components: {
    medicineSelector,
    CheckCircleFilled
  },
  data() {
    return {
      visible: this.modelValue,
      medicineFilter: "",
      columns: [
        {
          title: "名称",
          key: "itemName",
          dataIndex: "itemName",
          width: "25%",
          slots: {
            customRender: "itemName"
          }
        },
        {
          title: "用量",
          key: "quantity",
          dataIndex: "quantity",
          // ellipsis: true,
          width: "20%",
          slots: {
            customRender: "quantity"
          }
        },
        {
          title: "描述",
          key: "description",
          dataIndex: "description",
          // ellipsis: true,
          width: "35%",
          slots: {
            customRender: "description"
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "20%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      medicine: "",
      info: {},
      data: [],
      selectedRows: {},
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      editableData: reactive({}),
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {
  },
  activated() {

  },
  //获取父元素传值
  props: {
    record: {
      type: Object,
      default: {}
    },
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    medicineList() {
      let list = this.$store.state.medicineList;
      if (!this.medicineFilter) {
        return list.slice(0, 200);
      }
      return list.filter(item => item.name?.toLowerCase().includes(this.medicineFilter) || item.nameInitial?.toLowerCase().includes(this.medicineFilter)).slice(0, 200);
      // return [{ name: 1, id: 1 }]
    }
  },
  methods: {
    filterNameInitial(input) {
      this.medicineFilter = input?.toLowerCase();
      console.log(this.medicineList);
    },
    filter() {
      return true
    },
    async detail() {
      // console.log(this.record);
      let res = await Detail(this.record.uuid)

      // console.log(res)
      if (res.errCode == 0) {
        this.data = res?.data.items
        this.info = res?.data
        console.log(res?.data);

      } else {
        this.$message.error("详情查询失败", function () { });
      }
    },
    async onDelete(id) {
      this.editableData = {}
      let flag = this.data.find(item => item.id == id).quantity
      if (flag) {
        let res = await DeleteMedicine({ id })
        if (res.errCode == 0) {
          this.data = this.data.filter(function (item) {
            return item.id !== id;
          });
        }
      } else {
        console.log("没有初始数据，直接删除");
        this.data = this.data.filter(function (item) {
          return item.id !== id;
        });
      }
    },
    edit(id) {
      let _data = this.data;
      this.editableData[id] = cloneDeep(
        _data.filter(item => id === item.id)[0]
      );
    },
    async update() {
      await Update({ id: this.record.id, description: this.info.description })
    },
    async save(id) {
      let _data = this.data;

      if (!this.editableData[id].quantity) {
        this.$message.info("请输入用量", function () { });
        return
      }
      //判断editableData和修改前是否一致，如果一致则不修改
      if (JSON.stringify(this.data.find((item) => item.id == id)) === JSON.stringify(this.editableData[id])) {
        delete this.editableData[id];
        return;
      }
      let res;
      let params = { ...this.editableData[id], prescriptionId: this.record.id }
      if (params.type == 'new') {
        delete params.id
        console.log(params);
        res = await AddMedicine(params)
      } else {
        res = await UpdateMedicine(params)
      }
      if (res.errCode == 0) {
        this.detail()
        Object.assign(
          _data.filter(item => id === item.id)[0],
          this.editableData[id]
        );
        delete this.editableData[id];
      }
    },
    cancel(id) {
      delete this.editableData[id];
      let flag = this.data.find(item => item.id == id)?.quantity
      if (!flag) {
        this.data = this.data.filter(item => item.id != id)
      }
    },
    addToTable() {
      // console.log(this.data);
      let item = this.$store.state.medicineList.find(item => item.name == this.medicine)
      if (this.data.find(element => element.itemName == this.medicine)) {
        this.$message.info("已添加至列表", function () { });
        return
      }
      let id = item.id + 999999999
      let obj = { itemName: item.name, medicineId: item.id, id, quantity: undefined, description: undefined, type: "new" }
      this.data.push({ ...obj })
      this.editableData[id] = { ...obj }

      // console.log(this.modelInput.items)
      this.medicine = ""
    },
    closeModal() {
      this.$emit('update:modelValue', false);
    }
  },
  watch: {
    record(val) {
      this.detail()
    },
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.head {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
}
.head span {
  font-weight: bold;
  margin-right: 10px;
}
.style1 {
  position: relative;
}
.style1 > .box {
  padding-left: 5%;
  display: flex;
}
.inputSave {
  position: absolute;
  right: 5%;
  top: 28%;
  cursor: pointer;
}
.ant-modal-content {
  width: 800px !important;
}
.ant-modal-body {
  padding: 0 !important;
}
.ant-modal-footer {
  display: none !important;
}
</style>
