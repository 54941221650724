<template>
  <div>
    <a-modal v-model:visible="visible" :title="'穴位处方'" @ok="handleOk" okText="确认修改" cancelText="返回" :afterClose="closeModal" :okButtonProps="{ style: { display: 'none' }}" width="55vw">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
        <div class="style1">
          <div class="box">
            <label>处方名称：</label>
            <a-input v-model:value="info.name" style="width: 80%;margin-bottom: 20px;" />
          </div>
          <div class="box">
            <label>处方描述：</label>
            <a-textarea class="noResize" v-model:value="info.description" style="width: 80%;margin-bottom: 20px;" />
          </div>
          <div class="inputSave">
            <check-circle-filled style="color:#389e0d;font-size:25px" @click="update" />
          </div>
        </div>
        <a-table class="ant-table-striped" :columns="columns" :data-source="data" rowKey="id" :pagination="false" bordered>

          <template v-for="col in ['itemName', 'description']" #[col]="{ text, record }" :key="col">
            <div>
              <!-- <div>{{record.id}}</div> -->
              <a-input v-if="editableData[record.id]" v-model:value="editableData[record.id][col]" style="margin: -5px 0" />
              <template v-else>{{ text }}</template>
            </div>
            <!-- <div>
              <a-input-number v-model:value="record[col]" style="margin: -5px 0" />
            </div> -->
          </template>
          <template #operation="{ record }">
            <div class="flex_sa">
              <div class="editable-row-operations flex_sa">
                <span v-if="editableData[record.id]">
                  <a @click="save(record.id)">保存</a>
                </span>
                <span v-if="editableData[record.id]">
                  <a @click="cancel(record.id)">取消</a>
                </span>
                <span v-else>
                  <a @click="edit(record.id)">编辑</a>
                </span>
              </div>
              <div class="editable-row-operations">
                <span>
                  <a-popconfirm title="是否确认删除" @confirm="onDelete(record.id)" okText="确认" cancelText="取消">
                    <a>删除</a>
                  </a-popconfirm>
                </span>
              </div>

            </div>

          </template>
        </a-table>

        <div style="text-align: center;margin-top: 20px;">
          <a-button type="primary" @click="addToTable">添加</a-button>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import {
  CheckCircleFilled
} from "@ant-design/icons-vue";
import { Detail, Update, UpdateMedicine, AddMedicine, DeleteMedicine } from "/src/api/prescription.js";
import addPrescription from '/src/components/prescription/addPrescription.vue';
import prescriptionSelector from '/src/components/selector/prescriptionSelector.vue';
import editPrescription from '/src/components/prescription/editPrescription.vue';
import { cloneDeep } from "lodash-es";

export default {
  components: {
    addPrescription,
    prescriptionSelector,
    editPrescription,
    CheckCircleFilled
  },
  data() {
    return {
      id: "",
      visible: this.modelValue,
      visible2: false,
      visible3: false,
      visible4: false,
      showTable: 0,
      medicineFilter: "",
      title: "",
      columns: [
        {
          title: "名称",
          key: "itemName",
          dataIndex: "itemName",
          width: "15%",
          slots: {
            customRender: "itemName"
          }
        },
        {
          title: "说明",
          key: "description",
          dataIndex: "description",
          width: "30%",
          slots: {
            customRender: "description",
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "15%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      prescription: "",
      info: { description: "" },
      data: [],
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      selectedRow: {},
      editableData: {},
    };
  },
  emits: ['update:modelValue'],
  created() {
  },
  mounted() {
  },
  activated() {
  },
  //获取父元素传值
  props: {
    record: {
      type: Object,
      default: {},
    },
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
  },
  methods: {
    showModal() {
      this.visible = true
    },
    editPrescription(record) {
      this.visible4 = true;
      this.selectedRow = { ...record, id: record.prescription.id, uuid: record.prescription.uuid, name: record.prescription.name }
    },
    async detail() {
      console.log(this.record);
      let res = await Detail(this.record.uuid)

      if (res.errCode == 0) {
        this.data = res.data.items ? res.data.items : []
        this.info = res.data
        // this.info.name = this.record.name
      } else {
        this.$message.error("详情查询失败", function () { });
      }
    },
    async onDelete(id) {
      this.editableData = {}
      let item = this.data.find(item => item.id == id)
      console.log(item);

      if (item.itemName) {
        let res = await DeleteMedicine({ id })
        if (res.errCode == 0) {
          this.data = this.data.filter(function (item) {
            return item.id !== id;
          });
        }
      } else {
        console.log("没有初始数据，直接删除");
        this.data = this.data.filter(function (item) {
          return item.id !== id;
        });
      }
    },
    edit(id) {
      let _data = this.data;
      this.editableData[id] = cloneDeep(
        _data.filter(item => id === item.id)[0]
      );
    },
    async save(id) {
      let _data = this.data;

      //判断editableData和修改前是否一致，如果一致则不修改
      if (JSON.stringify(this.data.find((item) => item.id == id)) === JSON.stringify(this.editableData[id])) {
        delete this.editableData[id];
        return;
      }
      let res;
      let params = { ...this.editableData[id], prescriptionId: this.record.id, quantity: 1 }
      if (params.type == 'new') {
        delete params.id
        console.log(params);
        res = await AddMedicine(params)
      } else {
        res = await UpdateMedicine(params)
      }
      if (res.errCode == 0) {
        this.detail()
        Object.assign(
          _data.filter(item => id === item.id)[0],
          this.editableData[id]
        );
        delete this.editableData[id];
      }
    },
    async update() {
      await Update({ id: this.record.id, description: this.info.description, name: this.info.name })
    },
    cancel(id) {
      delete this.editableData[id];
      let flag = this.data.find(item => item.id == id)?.quantity
      if (!flag) {
        this.data = this.data.filter(item => item.id != id)
      }
    },
    closeModal() {
      this.$emit('update:modelValue', false);
      this.editableData = {}
      // this.record = {}
    },
    addToTable() {
      //判断对象是否为空
      if (Object.keys(this.editableData).length) {
        this.$message.info("请先完成当前操作", function () { });
        return
      }
      let id = Date.now() + Math.floor(Math.random() * 1000);
      let obj = { id, alias: "", aliasInitial: "", type: "new" }
      console.log(this);

      this.data.push({ ...obj })
      this.editableData[id] = { ...obj }
    },

  },
  watch: {
    record(val) {
      this.detail()
    },
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.thead {
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.5em;
}
.style1 {
  position: relative;
}
.style1 > .box {
  padding-left: 5%;
  display: flex;
}
.inputSave {
  position: absolute;
  right: 5%;
  top: 28%;
  cursor: pointer;
}
</style>
