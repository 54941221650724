<template>
  <div>
    <a-modal v-model:visible="visible" title="新增穴位处方" @ok="handleOk" okText="新建" cancelText="关闭" :afterClose="closeModal" width="30vw">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="名称">
          <a-input v-model:value="info.name" ref="inputRef" />
        </a-form-item>
        <a-form-item label="描述">
          <a-input v-model:value="info.description" />
        </a-form-item>
        <hr style="margin-bottom: 20px;">
        <div class="autoAdd">
          <a-input v-model:value="autoAddValue" />
          <a-button @click="autoAdd" style="margin-bottom: 15px;">一键添加</a-button>
        </div>

        <a-table class="ant-table-striped" :columns="columns" :data-source="data" rowKey="id" :pagination="false" bordered>
          <template v-for="col in ['itemName', 'description']" #[col]="{ text, record }" :key="col">
            <div>
              <a-input v-model:value="record[col]" style="margin: -5px 0" />
            </div>
          </template>
          <template #operation="{ record ,index}">
            <div class="flex_sa">
              <div class="editable-row-operations">
                <span>
                  <a @click="onDelete(index)">删除</a>
                </span>
              </div>
            </div>
          </template>
        </a-table>

        <div style="text-align: center;margin-top: 20px;">
          <a-button type="primary" @click="addToTable">添加</a-button>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { Add } from "/src/api/prescription.js";
import addPrescription from '/src/components/prescription/addPrescription.vue';
import { cloneDeep } from "lodash-es";
import prescriptionSelector from '/src/components/selector/prescriptionSelector.vue';

export default {
  components: {
    addPrescription,
    prescriptionSelector,
  },
  data() {
    return {
      id: "",
      visible: this.modelValue,
      visible2: false,
      visible3: false,
      visible4: false,
      autoAddValue: "",
      showTable: 0,
      medicineFilter: "",
      title: "",
      columns: [
        {
          title: "名称",
          key: "itemName",
          dataIndex: "itemName",
          width: "15%",
          slots: {
            customRender: "itemName"
          }
        },
        {
          title: "说明",
          key: "description",
          dataIndex: "description",
          width: "30%",
          slots: {
            customRender: "description",
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "15%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      prescription: "",
      info: [],
      data: [],
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      selectedRow: {},
      editableData: {},
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {

  },
  activated() {
  },
  //获取父元素传值
  props: {
    record: {
      type: Object,
      default: {},
    },
    type: {
      type: Object,
      default: {},
    },
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
  },
  methods: {

    edit(id) {
      let _data = this.data;

      this.editableData[id] = cloneDeep(
        _data.filter(item => id === item.id)[0]
      );
    },
    save(id) {
      let _data = this.data;
      if (!this.editableData[id].itemName) {
        this.$message.info("请输入完整信息", function () { });
        return
      }
      //判断editableData和修改前是否一致，如果一致则不修改
      if (JSON.stringify(this.data.find((item) => item.id == id)) === JSON.stringify(this.editableData[id])) {
        delete this.editableData[id];
        return;
      }
      // let res = await Add({ description: this.editableData[id].description, itemName: this.editableData[id].itemName, category: this.record.category, diseaseId: this.record.id })

      Object.assign(
        _data.filter(item => id === item.id)[0],
        this.editableData[id]
      );
      delete this.editableData[id];
    },
    cancel(id) {
      delete this.editableData[id];
      let flag = this.data.find(item => item.id == id)?.quantity
      if (!flag) {
        this.data = this.data.filter(item => item.id != id)
      }
    },
    onDelete(index) {
      this.data.splice(index, 1);
    },
    async handleOk() {
      let _this = this;
      // if (Object.keys(this.editableData).length) {
      //   this.$message.info("有未保存的信息，请先保存", function () { });
      //   return
      // }
      if (!this.info.name) {
        this.$message.info("请填写处方名称", function () { });
        return
      }
      this.data = this.data.filter(item => item.itemName)
      console.log(this.data);
      // return
      this.data.forEach(item => {
        item.quantity = 1
      })
      let obj = { ...this.info, items: this.data, type: "穴位处方", category: "穴位处方" }
      obj.items.forEach(item => {
        // 使用delete操作符删除id属性
        delete item.id;
      });
      let res = await Add(obj)
      if (res.errCode == 0) {
        _this.$message.success("新建成功", function () { });
        _this.visible = false
        this.info = {
          name: undefined,
          description: undefined,
          source: undefined
        }
        this.$store.dispatch('getPrescriptionList')
        this.data = [],
          this.$emit('getData', res.data);


      }

    },
    closeModal() {
      this.$emit('update:modelValue', false);
      this.editableData = {}
      // this.record = {}
    },
    addToTable() {
      //判断对象是否为空
      if (this.data.length === 0 || !this.data[this.data.length - 1].itemName) {
        if (this.data.length > 0) {
          this.$message.info("请先完成当前操作");
          return;
        }
        this.data.push({ itemName: "", description: "" });
      } else {
        this.data.push({ itemName: "", description: "" });
      }

      // let obj = { alias: "", aliasInitial: "", type: "new" }
      // this.data.push({ ...obj })
      // this.editableData[id] = { ...obj }
    },
    autoAdd() {
      this.data = this.data.filter(item => item.itemName)
      // 使用正则表达式分割字符串
      let result = this.autoAddValue.split(/[,，、\.。]/);
      // 过滤掉空字符串
      let filteredResult = result.filter(Boolean);
      filteredResult.forEach(item => {
        this.data.push({ itemName: item, description: "" });
      })
      this.autoAddValue = ""

    }
  },
  watch: {
    record(val) {
      console.log(val.name);
      this.info.name = val.name
      setTimeout(() => {
        console.log(this.$refs.inputRef);
        this.$refs.inputRef.focus()
      }, 200);

    },
    modelValue(val) {
      this.visible = val
    },
    "info.name"(val) {
      if (val === this.record.name + '1') {
        this.info.name = this.record.name + '·主穴'
      } else if (val === this.record.name + '2') {
        this.info.name = this.record.name + '·辅穴'
      }
    }
  }
};
</script>

<style scoped>
.thead {
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.5em;
}
.autoAdd {
  /* display: flex; */
  text-align: center;
  margin-bottom: 20px;
}
.autoAdd input {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 10px;
  display: block;
}
</style>
